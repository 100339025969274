/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact Page, with the dynamic form
    'page_template_contactP': {
      init: function() {
        if ( $( "#sujetD" ).length ) {
        	$("#sujet").val("Demande: " + $("#sujetD").text());
        }
      }
    },
 // Contact succès, avec la gestion du hover uniquement pour les grand écrans
    'page_template_succes': {
      init: function() {    	  
    	  $(".succesCont.notMobile").hover(
			 function() {
				  $(this).children(".fullBGImgHover").show();
				  $(this).children(".insideSucces").children(".succeshoverCont").show();
			  }, function() {
				  $(this).children(".fullBGImgHover").hide();
				  $(this).children(".insideSucces").children(".succeshoverCont").hide();
			  }
		  );
    	  
    	  $( ".succesCont" ).click(function() {
    		  var succesID = $(this).attr('id').replace('s', '');
    		  var dialog = document.querySelector('#dialog'+succesID);
    		  dialogPolyfill.registerDialog(dialog);
    		  dialog.showModal();
    		});
    	  
    	  $( ".closeDialog" ).click(function() {
    		  var succesID = this.id.match(/\d+/);
    		  var dialog = document.querySelector('#dialog'+succesID);
    		  dialogPolyfill.registerDialog(dialog);
    		  dialog.close();
    	  });
    	  
    	  $( ".prevDialog" ).click(function() {
    		  var succesID = this.id.match(/\d+/);
    		  var totalSucces = $('.succesDialog').length;
    		  var prev = 0;
    		  if(succesID ==1) {
    			  prev = totalSucces;
    		  } else {
    			  prev = parseInt(succesID) - 1; 
    		  }
    		  var dialog = document.querySelector('#dialog'+succesID);
    		  var dialogN = document.querySelector('#dialog'+prev);
    		  dialogPolyfill.registerDialog(dialog);
    		  dialog.close();
    		  dialogPolyfill.registerDialog(dialogN);
    		  dialogN.showModal();
    	  });
    	  
    	  $( ".nextDialog" ).click(function() {
    		  var succesID = this.id.match(/\d+/);
    		  var totalSucces = $('.succesDialog').length;
    		  var next = 0;
    		  if(succesID == totalSucces) {
    			  next = 1;
    		  } else {
    			  next = parseInt(succesID) + 1; 
    		  }
    		  var dialog = document.querySelector('#dialog'+succesID);
    		  var dialogN = document.querySelector('#dialog'+next);
    		  dialogPolyfill.registerDialog(dialog);
    		  dialog.close();
    		  dialogPolyfill.registerDialog(dialogN);
    		  dialogN.showModal();
    	  });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
